/* @import 'flowbite/dist/flowbite.css'; */



.btn{
  @apply bg-slate-400 text-white px-4 border 
}


/* @media screen(lg){
  body{
    color:red
  }
} */












body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.itemsidbar{
  padding: 3px;
  
  border-radius: 10px;
}

.d-center{
  display: flex;
  justify-content: center;
  align-items: center;

}

.bg-color a{
  color: white;
}



